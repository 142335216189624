import root from "window-or-global";
import {ApolloClient, from} from "@apollo/client";
import type {NormalizedCacheObject} from "@apollo/client";
import type {Dispatch} from "redux";
import type {ApolloConfigOptions} from "./apolloClientTypes";
import {createDefaultOptions} from "./apolloClientTypes";
import {createCache} from "./cacheConfig";
import {errorLink, uploadLink} from "./apolloLinks";

const CLIENT_KEY = "_tillsammansApolloClient";

declare global {
    // eslint-disable-next-line vars-on-top, no-underscore-dangle, no-var
    var _tillsammansApolloClient: ApolloClient<NormalizedCacheObject> | undefined;
}

export const createApolloClient = (
    dispatch: Dispatch,
    configOptions?: Partial<ApolloConfigOptions>,
): ApolloClient<NormalizedCacheObject> => {
    const {logger, devToolsEnabled} = createDefaultOptions(configOptions);

    const links = [errorLink(dispatch, logger), uploadLink()];

    return new ApolloClient({
        link: from(links),
        cache: createCache(),
        connectToDevTools: process.env.NODE_ENV === "development" || devToolsEnabled,
    });
};

export class ApolloClientInstance {
    static create(
        dispatch: Dispatch,
        configOptions?: Partial<ApolloConfigOptions>,
    ): ApolloClient<NormalizedCacheObject> {
        const client = root[CLIENT_KEY];

        if (!client) {
            root[CLIENT_KEY] = createApolloClient(dispatch, configOptions);
            return root[CLIENT_KEY];
        }

        return client;
    }

    static getClient() {
        if (!this.exists()) {
            throw new Error(
                `ApolloClientInstance '${CLIENT_KEY}' must be initiated to be used in the application.`,
            );
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return root[CLIENT_KEY]!;
    }

    static async clearCache() {
        if (this.exists()) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            await root[CLIENT_KEY]!.clearStore();
        }
    }

    static async reset() {
        if (this.exists()) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            await root[CLIENT_KEY]!.resetStore();
        }
    }

    static exists() {
        return !!root[CLIENT_KEY];
    }
}

export const getApolloClient = () => {
    try {
        return ApolloClientInstance.getClient();
    } catch {
        return null;
    }
};

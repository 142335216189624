import type {SagaIterator} from "redux-saga";
import {t} from "@lingui/macro";
import type {ApolloError} from "@apollo/client";
import {call, put, select, take, takeLatest, takeLeading} from "redux-saga/effects";
import {AuthActions, AuthSelectors} from "@atg-shared/auth";
import type {MemberTeam} from "@atg-tillsammans/types/generated";
import {TeamPageUtils} from "@atg-tillsammans/team-utils";
import log from "@atg-tillsammans/log";
import {ApolloClientHelpers} from "@atg-tillsammans/apollo-data-access";
import {
    LOGIN_FINISHED,
    LOGOUT_FINISHED,
    RECEIVE_USER,
} from "@atg-global-shared/user/userActionTypes";
import * as ModalActions from "atg-modals/modalActions";
import {TeamService} from "../../services";
import * as TeamActions from "./team.actions";
import * as TeamSelectors from "./team.selectors";
import * as TeamApi from "./teamApi";

export function* initMyTeams(): SagaIterator {
    yield put(TeamActions.fetchMyTeams(true));
}

export function* fetchMyTeams({payload}: TeamActions.FetchMyTeamsAction): SagaIterator {
    const isLoggedIn = yield select(AuthSelectors.isLoggedIn);

    if (!isLoggedIn) return;

    const result = yield call(TeamService.fetchMyTeams, payload.refreshTeams);

    if (result !== null) {
        /*
         *  Update apollo cache with teams.
         */
        TeamService.setMyTeams(result);

        /*
         *  Pupulate redux state for my teams.
         */
        yield put(TeamActions.setMyTeams(result));
    }
}

/**
 * @param action: CreateTeamAction
 * @returns
 */
export function* createTeam({payload}: TeamActions.CreateTeamAction): SagaIterator {
    yield put(AuthActions.checkAuth(true));

    const authResult = yield take(AuthActions.AUTH_CHECK_RESPONSE);

    if (authResult.error) {
        return;
    }

    try {
        const result = yield call(TeamService.createTeam, payload);

        if (result?.team?.id) {
            yield put(TeamActions.teamCreated(result.team.id));
        }

        if (result?.errors) {
            const message = TeamPageUtils.getTeamSettingsErrorMessage(result?.errors);
            yield put(TeamActions.createTeamError(message));
        }
    } catch (error: unknown) {
        const message = TeamPageUtils.getTeamSettingsErrorMessage(error as ApolloError);
        yield put(TeamActions.createTeamError(message ?? t`Okänt tekniskt fel`));
        log.error(error);
    }
}

/**
 * Sometimes teams have not been added to the BE cache before we try to get them again and the new team
 * will not be available in my teams. To prevent this we check if the team is already fetched or else
 * reloads my teams.
 *
 * @param action: TeamCreatedAction
 * @returns
 */
export function* teamCreated({payload}: TeamActions.TeamCreatedAction): SagaIterator {
    const isLoggedIn = yield select(AuthSelectors.isLoggedIn);

    if (!isLoggedIn) return;

    const teams: MemberTeam[] | null = yield call(TeamService.getMyTeams, {isLoggedIn});

    if (!teams || (teams && !teams.find(({id}) => payload.teamId === id))) {
        yield put(TeamActions.fetchMyTeams(true));
    }
}

export function* getTeamMembers(
    action: TeamActions.ReloadTeamMembersAction | TeamActions.SetTeamAction,
): SagaIterator {
    const isLoggedIn = yield select(AuthSelectors.isLoggedIn);
    yield put(TeamActions.getTeamMembers(action.payload.teamId, !isLoggedIn));
}

export function* resetTeams(): SagaIterator {
    yield put(TeamActions.resetMyTeams());
}

export function* uploadTeamImage({
    payload,
}: TeamActions.UploadTeamImageAction): SagaIterator {
    const teamId = yield select(TeamSelectors.getTeamId);
    try {
        const response = yield call(TeamApi.saveTeamImage, teamId, payload.file);
        if (response.ok) {
            yield put(TeamActions.updateTeamImage(response.data.imageUrl));
            yield put(ModalActions.closeImageUploadModal());
            yield put(TeamActions.uploadTeamImageSuccess(response.data.imageUrl));
            // refetch apollo cache to get new image
            yield call(ApolloClientHelpers.updateImage, teamId);
        }
    } catch (error: unknown) {
        yield put(
            TeamActions.uploadTeamImageError((error as Error).message, error as Error),
        );
    }
}

export default function* teamSaga() {
    yield takeLatest(LOGOUT_FINISHED, resetTeams);
    yield takeLeading([LOGIN_FINISHED, RECEIVE_USER], initMyTeams);
    yield takeLatest(TeamActions.REQUEST_TEAMS, fetchMyTeams);
    yield takeLatest(
        [TeamActions.RELOAD_TEAM_MEMBERS, TeamActions.RECEIVE_TEAM],
        getTeamMembers,
    );
    yield takeLatest(TeamActions.UPLOAD_TEAM_IMAGE, uploadTeamImage);
    yield takeLatest(TeamActions.TEAM_CREATED, teamCreated);
    yield takeLatest(TeamActions.CREATE_TEAM, createTeam);
}

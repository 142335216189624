import {fetchAuthorized} from "@atg-shared/auth";
import {
    COUPON_SERVICE_URL,
    USER_SERVICE_URL,
    VARENNE_COUPON_TEAMS_URL,
} from "@atg-shared/service-url";
import type {Coupon} from "@atg-horse-shared/coupon-types";
import type {
    BetTransaction,
    Pagination,
    PlacedBetReceipts,
    SourceSystem,
} from "@atg-horse-shared/bet-history-types";
import type {
    LegacyPlacedBetReceiptsResponse,
    SharedBetInfoResponse,
} from "./sharedBet.types";

export const fetchStartedCoupons = () =>
    fetchAuthorized<Array<Coupon>>(COUPON_SERVICE_URL, {
        method: "GET",
    });

export const fetchConditions = (id: string) =>
    fetchAuthorized<SharedBetInfoResponse>(`${VARENNE_COUPON_TEAMS_URL}/${id}`, {
        method: "GET",
    });

const buildUrl = (url: string, params?: Record<string, string | number | boolean>) => {
    if (!params) return url;
    return `${url}?${Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join("&")}`;
};

export const fetchTransactions = ({
    fromDate,
    toDate,
    timestamp,
}: {
    fromDate: string;
    toDate: string;
    timestamp: number;
}) =>
    fetchAuthorized<Pagination<BetTransaction>>(
        buildUrl("/services/tokenized-proxy/horse-bet-history/api/v2/bet/transactions", {
            fromDate,
            toDate,
            page: 0,
            size: 50,
            usingCache: false,
            timestamp,
            ongoingAndUpcoming: false,
        }),
        {
            method: "GET",
        },
    );

export const fecthReceipt = (id: string, sourceSystem: SourceSystem) =>
    fetchAuthorized<PlacedBetReceipts>(
        `/services/tokenized-proxy/horse-bet-history/api/v2/bet/receipts/${id}?sourceSystem=${sourceSystem}`,
    );

export const fecthNonVarenneReceipt = (id: string) =>
    fetchAuthorized<LegacyPlacedBetReceiptsResponse>(
        `${USER_SERVICE_URL}/bets/${id}?forceBetHistory=true`,
    );

/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import {BetType} from "@atg-horse-shared/bet-types";
import {PreBetShareInfo} from "./preBetShareInfo";
import {t} from "@lingui/macro";

// TODO: import from atg-shop once ciruclar dependency is resolved
// import {TeamType as ShopShareTeamType} from "atg-shops-share-receipt/horseVarenne/types/shareHorseReceiptTypesVarenne";
export type ShopShareTeamType = "TILLSAMMANS" | "SHOP_SHARE" | "COUPON_TEAM";

/**
 * List of bets for a given time period
 * @export
 * @interface BetTransaction
 */
export interface BetTransaction {
    betType: BetType;

    offeringId: string;

    gameStatus?: GameStatus;

    startTime?: Date;

    trackName?: string;

    id: string;

    placedAt: Date;

    technicalChannel: TechnicalChannel;

    payout?: number;

    cost: number;

    sourceSystem: SourceSystem;
    /**
     * In case of shared bets (sourceSystem SBH) it is not possible to identify if it is a file bet or not.
     */
    isFileBet?: boolean;

    isPreBetShare: boolean;

    raceDate: string;

    preBetShareInfo?: PreBetShareInfo;

    shareStatus?: ShareStatus;

    teamType?: TeamType;
}

/**
 * List of bets for a given time period only for Varenne bets (CBH & SBH)
 * @export
 * @type BetTransactionFromVarenne
 */
export type BetTransactionFromVarenne = Omit<BetTransaction, "sourceSystem"> & {
    sourceSystem: SourceSystem.CBH | SourceSystem.SBH;
};

/**
 * @export
 * @enum {string}
 */
export enum SourceSystem {
    HBH = "HBH",
    CBH = "CBH",
    SBH = "SBH",
}
/**
 * @export
 * @enum {string}
 */
export enum ShareStatus {
    PLACED = "PLACED",
    REFUND = "REFUND",
    OPEN = "OPEN",
    UNPLACED = "UNPLACED",
    UNKNOWN = "UNKNOWN",
}
/**
 * @export
 * @enum {string}
 */
export enum TeamType {
    TILLSAMMANS = "TILLSAMMANS",
    SHOP_SHARE = "SHOP_SHARE",
    COUPON_TEAM = "COUPON_TEAM",
}

export enum GameStatus {
    UPCOMING = "Kommande",
    DECIDED = "Avgjord",
    ONGOING = "Pågår",
}

export const getGameStatusTrans = (status?: GameStatus) => {
    if (!status) return "";
    return (
        {
            [GameStatus.UPCOMING]: t`Kommande`,
            [GameStatus.DECIDED]: t`Avgjord`,
            [GameStatus.ONGOING]: t`Pågår`,
        }[status] || ""
    );
};
/**
 * @export
 * @enum {string}
 */
export enum TechnicalChannel {
    WEB_ATGSE = "ATG.se",
    WEB_TILLSAMMANS = "ATG Tillsammans",
    ATG_CENTRAL_T = "ATG centralt",
    INTERNET_ANDELSSPEL = "Andelsspel",
    MOBIL_APP = "ATG mobilapp",
    MOBIL = "Mobil",
    APP_ANDROID = "ATG mobilapp",
    APP_IOS = "ATG mobilapp",
    TRACK = "Bana",
    AGENT = "Ombud",
    AGENT_ONLINE = "Ombudslag på internet",
    RETAIL_TERMINAL = "Ombud",
    ATG_TILLSAMMANS_FILE_BET = "ATG Tillsammans Filspel",
    FILE_BET = "Filspel",
    INTERNET_REDUCED_BET = "Reducerat spel",
    TOGETHER_TEAM_REDUCED_BET = "ATG Tillsammans Filspel",
}

type TechnicalChannelKey = keyof typeof TechnicalChannel;

export const getTechnicalChannelTrans = (channel?: TechnicalChannelKey) => {
    if (!channel) return "";
    return (
        {
            WEB_ATGSE: t`ATG.se`,
            WEB_TILLSAMMANS: t`ATG Tillsammans`,
            ATG_CENTRAL_T: t`ATG centralt`,
            INTERNET_ANDELSSPEL: t`Andelsspel`,
            MOBIL_APP: t`ATG mobilapp`,
            MOBIL: t`Mobil`,
            APP_ANDROID: t`ATG mobilapp`,
            APP_IOS: t`ATG mobilapp`,
            TRACK: t`Bana`,
            AGENT: t`Ombud`,
            AGENT_ONLINE: t`Ombudslag på internet`,
            RETAIL_TERMINAL: t`Ombud`,
            ATG_TILLSAMMANS_FILE_BET: t`ATG Tillsammans Filspel`,
            FILE_BET: t`Filspel`,
            INTERNET_REDUCED_BET: t`Reducerat spel`,
            TOGETHER_TEAM_REDUCED_BET: t`ATG Tillsammans Filspel`,
        }[channel] || ""
    );
};

/**
 * Convert teamType that is possibly ShopShareTeamType to TeamType enum
 */
export const teamTypeToEnum = (teamType?: ShopShareTeamType | TeamType) => {
    switch (teamType) {
        case "TILLSAMMANS":
            return TeamType.TILLSAMMANS;
        case "SHOP_SHARE":
            return TeamType.SHOP_SHARE;
        case "COUPON_TEAM":
            return TeamType.COUPON_TEAM;
        default:
            return teamType;
    }
};

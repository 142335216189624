import {take, takeLeading, call} from "redux-saga/effects";
import type {Dispatch} from "redux";
import type {SagaIterator} from "redux-saga";
import log from "@atg-shared/log";
import {subscribe} from "@atg-frame-shared/push";
import * as PushActions from "@atg-frame-shared/push-saga/domain/pushActions";
import * as UserActions from "@atg-global-shared/user/userActionTypes";
import {USER_ENTERED_TILLSAMMANS} from "@atg-tillsammans/app-data-access/redux";
import * as ChatActions from "./teamChatActions";
import {type MessageAction} from "./teamChat";

export const getChatMessageCallback =
    (dispatch: Dispatch<any>) => (action: MessageAction) => {
        log.debug({type: "chat", message: action.comment});

        switch (action.actionType) {
            case "CREATE":
                dispatch(ChatActions.addMessage(action.comment));
                break;
            case "UPDATE":
                dispatch(ChatActions.changeMessageText(action.comment));
                break;
            case "DELETE":
                dispatch(ChatActions.deleteMessage(action.comment.id));
                break;
            case "PIN":
                dispatch(ChatActions.pinOrUnpinMessage(action.comment));
                break;
            default:
                break;
        }
    };

export function* subscribeToChat(dispatch: Dispatch<any>): SagaIterator<void> {
    const messageCallback = yield call(getChatMessageCallback, dispatch);
    const topic = "chat/>";
    const unsubscribe = yield call(subscribe, topic, messageCallback, false, true);
    const reconnectAction = yield take([
        UserActions.LOGOUT_INITIATED,
        PushActions.RECONNECTED,
    ]);

    yield call(unsubscribe);

    if (reconnectAction.type === PushActions.RECONNECTED) {
        yield call(subscribeToChat, dispatch);
    }
}

export default function* chatListenerSaga(dispatch: Dispatch<any>) {
    yield takeLeading(
        [UserActions.LOGIN_FINISHED, UserActions.RECEIVE_USER, USER_ENTERED_TILLSAMMANS],
        subscribeToChat,
        dispatch,
    );
}

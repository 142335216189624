import {map} from "lodash";
import {now, parseDateTimestamp} from "@atg-shared/datetime";
import type {Track} from "@atg-tillsammans-shared/types";
import {GameType} from "@atg-tillsammans-shared/types";
import {colors} from "@atg-tillsammans-shared/css";
import {formatGameType} from "./textFormatter";

export const toGameType = (gameType: string): GameType =>
    Object.values(GameType).find((type) => type === gameType.toUpperCase()) ??
    GameType.V75;

export const parseGameType = (gameId: string | null | undefined): GameType =>
    gameId ? toGameType(gameId.split("_")[0]) : GameType.V75;

export const getGameTypeColor = (gameType: GameType) => {
    switch (gameType) {
        case GameType.V64:
            return colors.gameType.V64.default;
        case GameType.V86:
            return colors.gameType.V86.default;
        case GameType.GS75:
            return colors.gameType.GS75.default;
        case GameType.V75:
        case GameType.BIG9:
        default:
            return colors.gameType.V75.default;
    }
};

export const getFormatedDate = (startTime: string) => {
    const startDate = parseDateTimestamp(startTime);
    const displayDate = startDate.isBefore(now(), "day")
        ? startDate.format("dddd D MMM")
        : startDate.format("dddd LT");
    return displayDate.charAt(0).toUpperCase() + displayDate.slice(1);
};

export const formatDate = (startTime: string, format = "dddd D MMMM") => {
    const displayDate = parseDateTimestamp(startTime).format(format);
    return displayDate.charAt(0).toUpperCase() + displayDate.slice(1);
};

export const getFormatedTracks = (tracks: Track[]) => {
    const trackNames = map(tracks, "name").join(", ");
    return trackNames.replace(/, ([^, ]*)$/, " & $1");
};

export const getWeekDay = (gameId: string | undefined): string => {
    if (!gameId) return "";
    const gameIdGroups = gameId.split("_");
    return parseDateTimestamp(gameIdGroups[1]).format("dddd");
};

export const getGameTypeAndWeekDay = (gameId: string | undefined): string => {
    if (!gameId) return "";
    const gameIdGroups = gameId.split("_");
    return `${formatGameType(gameIdGroups[0] as GameType)} ${formatDate(
        gameIdGroups[1],
        "dddd",
    ).toLowerCase()}`;
};

export function parseGameId(
    gameId: string | null | undefined,
    options: {formatDate?: (date: string) => string} = {
        formatDate: (date: string) => parseDateTimestamp(date).format("dddd"),
    },
): {gameType: GameType; date: string; trackId: number; raceNumber: number} | null {
    if (!gameId) return null;

    const [gameType, date, trackId, raceNumber] = gameId.split("_");

    return {
        gameType: toGameType(gameType),
        date: options?.formatDate ? options.formatDate(date) : date,
        trackId: parseInt(trackId, 10),
        raceNumber: parseInt(raceNumber, 10),
    };
}

import {call, put, take, takeLatest} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import {t} from "@lingui/macro";
import * as AuthActions from "@atg-shared/auth/domain/authActions";
import {TeamInviteStatus} from "@atg-tillsammans/types";
import {ToastContainerId} from "@atg-tillsammans/toasts-types";
import {Paths} from "@atg-tillsammans-shared/navigation";
import * as ToastActions from "atg-ui-toast/domain/toastActions";
import {ImageUtils} from "@atg-tillsammans-shared/image-utils";

import history from "atg-history";
import {TeamActions} from "../team";
import * as InviteApi from "./inviteApi";
import * as InviteActions from "./invite.actions";

const toastMessages = (status: string): string =>
    ({
        [TeamInviteStatus.JOINED]: t({
            id: "team.toast.message.JOINED",
            message:
                "Du är nu medlem i laget. Missa inte att köpa en andel för att delta i omgångens spel...",
        }),
        [TeamInviteStatus.QUEUED]: t({
            id: "team.toast.message.QUEUED",
            message:
                "Laget är tyvärr fullt just nu men du är placerad i kö och blir medlem när en plats blir ledig.",
        }),
        [TeamInviteStatus.ALREADY_QUEUED]: t({
            id: "team.toast.message.ALREADY_QUEUED",
            message: "Du är redan placerad i kö och blir medlem när en plats blir ledig.",
        }),
        [TeamInviteStatus.TO_MANY_TEAMS]: t({
            id: "team.toast.message.TO_MANY_TEAMS",
            message:
                "Attans! Du kan inte gå med i fler lag eftersom du nått maxgränsen för antal lag per person. För att gå med i det här laget behöver du först gå ur ett annat lag och sen klicka på inbjudningslänken igen.",
        }),
        [TeamInviteStatus.INVALID_INVITE]: t({
            id: "team.toast.message.INVALID_INVITE",
            message: "Inbjudningskoden var tyvärr ogiltig.",
        }),
        [TeamInviteStatus.ALREADY_MEMBER]: t({
            id: "team.toast.message.ALREADY_MEMBER",
            message:
                "Hoppsan! Du verkar ha använt en inbjudningslänk till ett lag du redan var medlem i.",
        }),
        [TeamInviteStatus.TECHNICAL_ERROR]: t({
            id: "team.toast.message.TECHNICAL_ERROR",
            message: "Något gick fel. Försök igen senare.",
        }),
    }[status]);

export function* acceptInvite(
    action: InviteActions.AcceptInviteAction,
): SagaIterator<void> {
    yield put(AuthActions.checkAuth(true));
    const authResult = yield take(AuthActions.AUTH_CHECK_RESPONSE);
    if (authResult.error) return;

    const {teamId, inviteCode, gameId} = action.payload;

    try {
        const {status, team} = yield call(InviteApi.joinTeamByInvite, teamId, inviteCode);

        switch (status) {
            case TeamInviteStatus.JOINED:
            case TeamInviteStatus.ALREADY_MEMBER:
                if (status === TeamInviteStatus.JOINED) {
                    yield put(TeamActions.memberJoinedTeam({team}));
                }
                yield call(
                    history.push,
                    gameId
                        ? Paths.getTeamGamePagePath(teamId, gameId)
                        : Paths.getTeamPagePath(teamId),
                );
                if (status === TeamInviteStatus.JOINED)
                    yield put(
                        ToastActions.showToast({
                            containerId: ToastContainerId.TEAM_PAGE,
                            type: "success",
                            message: toastMessages(status),
                            timeout: 10000,
                            reason: status,
                        }),
                    );
                break;
            default:
                yield call(history.push, Paths.getMyTeamsPath());
                yield put(
                    ToastActions.showToast({
                        type: "warning",
                        message:
                            toastMessages(status) ??
                            toastMessages(TeamInviteStatus.TECHNICAL_ERROR),
                        timeout: 10000,
                        reason: status ?? TeamInviteStatus.TECHNICAL_ERROR,
                    }),
                );
                break;
        }
    } catch (error: unknown) {
        yield call(history.push, Paths.getMyTeamsPath());
        yield put(
            ToastActions.showToast({
                type: "danger",
                message: toastMessages(TeamInviteStatus.TECHNICAL_ERROR),
                timeout: 10000,
                reason: TeamInviteStatus.TECHNICAL_ERROR,
            }),
        );
    }
}

export function* fetchInviteInfo({
    payload,
}: InviteActions.FetchInviteInfoAction): SagaIterator<void> {
    const {inviteCode, userName} = payload;
    try {
        const inviteInfoResponse = yield call(
            InviteApi.fetchInviteInfo,
            inviteCode,
            userName,
        );
        if (!inviteInfoResponse || inviteInfoResponse.meta.code === 404) {
            yield put(InviteActions.inviteErrorResponse(inviteInfoResponse.data));
        } else {
            const inviter = {
                ...inviteInfoResponse.data.inviter,
            };

            if (inviter.imageUrl) {
                inviter.imageUrl = ImageUtils.getImageRef(inviter.imageUrl);
            }

            yield put(
                InviteActions.inviteInfoResponse({
                    ...inviteInfoResponse.data,
                    inviter,
                }),
            );
        }
    } catch (error: unknown) {
        yield put(
            InviteActions.inviteErrorResponse({
                // @ts-expect-error
                message: error.response.data.message,
                // @ts-expect-error
                ...error,
            }),
        );
    }
}

export default function* InviteSaga(): SagaIterator<void> {
    yield takeLatest(InviteActions.FETCH_INVITE_INFO_REQUEST, fetchInviteInfo);
    yield takeLatest(InviteActions.ACCEPT_INVITE_ACTION, acceptInvite);
}

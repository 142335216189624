import {plural, t} from "@lingui/macro";
import {GameType} from "@atg-tillsammans-shared/types";

type NumberFormatOptions = {
    delimiter?: string;
    prependUnit?: string;
    appendUnit?: string;
};

/**
 * Formats numbers into a string of spliting the number into blocks of three digits with a given delimiter. Default delimiter is a blank space.
 *
 * Optionaly you can give the number a different delimiter you can choose to have an unit before and/or after the number.
 *
 * e.g. 1337 will become 1 337 and 1000000 will become 1 000 000.
 */
export const formatNumber = (
    num: number,
    options: NumberFormatOptions = {delimiter: " "},
) => {
    const {delimiter, prependUnit, appendUnit} = options;
    let prettyNumber = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimiter ?? " ");
    if (prependUnit) prettyNumber = `${prependUnit} ${prettyNumber}`;
    if (appendUnit) prettyNumber = `${prettyNumber} ${appendUnit}`;
    return prettyNumber;
};

export type GameTypeFormatOptions = {
    uppercase?: boolean;
};

export const formatGameType = (
    gameType: GameType,
    {uppercase}: GameTypeFormatOptions = {uppercase: false},
): string => {
    switch (gameType) {
        case GameType.BIG9: {
            const text = t({id: "common.game.big9", message: "Big 9"});
            return uppercase ? text.toUpperCase() : text;
        }
        default:
            return gameType;
    }
};

type GameTypesOptions = {
    lastDelimiter?: string;
};

export const formatGameTypes = (
    gameTypes: GameType[],
    {lastDelimiter}: GameTypesOptions = {lastDelimiter: "&"},
) => {
    const types = gameTypes.map((gameType) => formatGameType(gameType)).join(", ");
    return types.replace(/, ([^,.*]*)$/, ` ${lastDelimiter} $1`);
};

export const formatJackpotAmount = (amount: number) => {
    const number = Math.round(amount / 100000) / 10;
    const jackpotAmount = formatNumber(number < 10 ? number : Math.floor(number), {});
    if (+jackpotAmount < 1) return "";
    return `${jackpotAmount} ${plural(jackpotAmount, {
        one: "Miljon",
        other: "Miljoner",
    })}`;
};

export const formatCapitalized = (value: string): string => {
    const hasDash = value.includes("-");
    const text = value.replace("-", " ").split(" ");
    const newText = text.reduce<string[]>((acc, s) => {
        if (s) {
            return [...acc, s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()];
        }
        return acc;
    }, []);
    return newText.join(hasDash ? "-" : " ");
};

/**
 * TODO: Add market specific rules
 *
 * @param value
 * @returns
 */
export const formatGenitiveCase = (value: string): string => {
    // Swedish rules for genitive case
    if (value.endsWith("s")) return `${value}`;
    return `${value}s`;
};

import {t} from "@lingui/macro";
import {formatGenitiveCase, parseGameId} from "@atg-tillsammans-shared/utils";
import {
    GameStatus as BetGameStatus,
    type BetTransaction,
    type PreBetShareInfo,
} from "@atg-horse-shared/bet-history-types";
import {GameStatus} from "@atg-tillsammans-shared/types";
import type {
    PrivateShareParticipant,
    ShareHolder,
} from "@atg-tillsammans-shared/shared-bet-types";

export const getSharedBetName = ({
    name,
    firstName,
}: Pick<ShareHolder, "name" | "firstName">) => {
    if (!name) return t`Okänt`;
    return t`${formatGenitiveCase(firstName ?? name.split(" ")[0])} andelsspel`;
};

export const mapShareHolder = ({
    shareName = "",
    numberOfShares = 0,
}: Partial<PreBetShareInfo> = {}): ShareHolder => {
    const [firstName, ...rest] = shareName.split(" ");
    return {
        name: shareName,
        firstName,
        lastName: rest.join(" "),
        nrShares: numberOfShares,
    };
};

export const toShareHolder = (fullname: string, numberOfShares = 0): ShareHolder =>
    mapShareHolder({numberOfShares, shareName: fullname});

export const mapGameStatus = (status?: BetGameStatus): GameStatus => {
    switch (status) {
        case BetGameStatus.DECIDED:
            return GameStatus.RESULTS;
        case BetGameStatus.ONGOING:
            return GameStatus.ONGOING;
        default:
            return GameStatus.UPCOMING;
    }
};

export const createTransactionInfoResponse = (
    transaction: BetTransaction,
    couponId?: string,
    tns?: string,
): PrivateShareParticipant => {
    const gameId = transaction.offeringId;

    const persedGameId = parseGameId(gameId, {
        formatDate: (date: string) => date,
    });

    const scheduledStartTime =
        transaction.startTime?.toString() ?? persedGameId?.date ?? "";

    const initiator = mapShareHolder(transaction.preBetShareInfo);

    const idParts = transaction.id.split("_");

    return {
        id: transaction.id,
        teamId: idParts[0] !== transaction.id ? idParts[0] : undefined,
        sharedBet: {
            name: getSharedBetName(initiator),
            type: "PRIVATE_TEAM",
            initiator,
            shareStatus: transaction.shareStatus,
        },
        game: {
            id: gameId,
            gameId: persedGameId
                ? {
                      gameType: persedGameId.gameType,
                      gameDate: persedGameId.date,
                      gameAreaCode: persedGameId.trackId,
                      gameRaceNumber: persedGameId.raceNumber,
                  }
                : undefined,
            scheduledStartTime,
            tracks: [],
            gameStatus: mapGameStatus(transaction.gameStatus),
        },
        numberOfShares: transaction.preBetShareInfo?.numberOfShares ?? -1,
        totalCost: transaction.cost,
        couponId,
        tns,
    };
};

import {now} from "@atg-shared/datetime";
import type {PrivateShareParticipant} from "@atg-tillsammans-shared/shared-bet-types";

export const STARTED_COUPONS_FETCH = "sharedBet/STARTED_COUPONS_FETCH";
export const STARTED_COUPONS_SUCCESS = "sharedBet/STARTED_COUPONS_SUCCESS";
export const STARTED_COUPONS_ERROR = "sharedBet/STARTED_COUPONS_ERROR";
export const STARTED_COUPONS_RESET = "sharedBet/STARTED_COUPONS_RESET";

export type StartedCouponsFetchAction = {
    type: typeof STARTED_COUPONS_FETCH;
    payload: {
        timestamp: number;
        forceFetch: boolean;
        enhance: boolean;
    };
};

export type StartedCouponsFetchSuccessAction = {
    type: typeof STARTED_COUPONS_SUCCESS;
    payload: {
        data: Record<string, PrivateShareParticipant>;
    };
};

export type StartedCouponsFetchErrorAction = {
    type: typeof STARTED_COUPONS_ERROR;
    error: boolean;
    payload: {
        error?: unknown;
    };
};

type StartedCouponsResetAction = {
    type: typeof STARTED_COUPONS_RESET;
};

export type SharedBetAction =
    | StartedCouponsFetchAction
    | StartedCouponsFetchSuccessAction
    | StartedCouponsFetchErrorAction
    | StartedCouponsResetAction;

export const SharedBetActions = {
    fetchStartedCoupons: ({
        forceFetch = false,
        enhance = false,
        timestamp = now().unix(),
    }: {
        forceFetch?: boolean;
        enhance?: boolean;
        timestamp?: number;
    } = {}): StartedCouponsFetchAction => ({
        type: STARTED_COUPONS_FETCH,
        payload: {
            forceFetch,
            enhance,
            timestamp,
        },
    }),
    fetchStartedSuccess: (
        data: Record<string, PrivateShareParticipant>,
    ): StartedCouponsFetchSuccessAction => ({
        type: STARTED_COUPONS_SUCCESS,
        payload: {
            data,
        },
    }),
    fetchStartedError: (error?: unknown): StartedCouponsFetchErrorAction => ({
        type: STARTED_COUPONS_ERROR,
        error: true,
        payload: {
            error,
        },
    }),
    resetStartedCoupons: (): StartedCouponsResetAction => ({
        type: STARTED_COUPONS_RESET,
    }),
};

/*
 * This file contains helper functions for manipilating urls used to be used with AWS Serverless Image Handler.
 */
import type {ImageProps, ImageUrl} from "@atg-tillsammans-shared/image-types";

const webImageUrl = /^https?:\/\/[a-z\d_\-./\\]+\.(jpg|jpeg|gif|png|svg|jfif)$/;
const webImageUrlWithoutGif = /^https?:\/\/[a-z\d_\-./\\]+\.(jpg|jpeg|png|svg|jfif)$/;

const isValidUrl = (imageUrl: ImageUrl, allowGif = true) =>
    allowGif
        ? webImageUrl.test(imageUrl.toLowerCase())
        : webImageUrlWithoutGif.test(imageUrl.toLowerCase());

const isAllowedExt = (imageUrl: ImageUrl | string) =>
    imageUrl.match(/^(.(?!.*\.gif$))*$/g);

const isAllowedImage = (imageUrl: ImageUrl | string) => {
    const extension = imageUrl?.split(".")?.pop()?.toLowerCase();
    return extension
        ? ["jpg", "jpeg", "gif", "png", "svg", "jfif"].includes(extension)
        : false;
};

// eslint-disable-next-line prefer-regex-literals
const regexp = RegExp("(https?://.*?)(/.*)");

const split = (imageUrl: ImageUrl) => {
    const match = imageUrl.match(regexp);
    return match && match.length === 3 ? {host: match[1], path: match[2]} : null;
};

const mapSize = ({width = 0, height = 0}: ImageProps): string =>
    width > 0 || height > 0 ? `/${width}x${height}` : "";

const size = (props: ImageProps): string => {
    const {imageUrl} = props;
    const image = split(imageUrl);
    return image ? `${image.host}${mapSize(props)}${image.path}` : imageUrl;
};

const fitIn = (props: ImageProps): string => {
    const {imageUrl} = props;
    const image = split(imageUrl);
    return image ? `${image.host}/fit-in${mapSize(props)}${image.path}` : imageUrl;
};

type ImageFilterOptions = {
    blur?: number;
    /*
     *  ...
     */
};

const mapFilterOptions = ({blur}: ImageFilterOptions) => {
    let filters = "";
    if (blur) filters += `:blur(${blur})`;
    return filters ? `/filters${filters}` : "";
};

const filter = (props: ImageProps, options: ImageFilterOptions = {}): string => {
    const {imageUrl} = props;
    const image = split(imageUrl);
    return image
        ? `${image.host}${mapSize(props)}${mapFilterOptions(options)}${image.path}`
        : imageUrl;
};

export {isValidUrl, isAllowedExt, isAllowedImage, fitIn, size, filter};

import * as React from "react";
import type {SerializedStyles} from "@emotion/react";
import {t} from "@lingui/macro";
import {
    type ImageUrl,
    type ResponsiveImageProps,
    type BorderProps,
    ASPECT_RATIO_1_1,
} from "@atg-tillsammans-shared/image-types";
import {useTheme} from "@atg-ui/toolkit/theme";
import EMPTY_IMAGE from "../../assets/images/empty.svg";
import {Image} from "../Image";
import {ResponsiveImage} from "../ResponsiveImage";
import styles from "./AvatarImage.styles";

type Props = {
    style?: SerializedStyles;
    imageUrl: ImageUrl | null;
    size?: number;
    defaultImageSrc?: string;
    border?: boolean;
    borderOptions?: BorderProps;
    rounded?: boolean;
    shadow?: boolean;
    background?: string;
    alt?: string;
    showTooltip?: boolean;
} & ResponsiveImageProps;

export function AvatarImage({
    style,
    imageUrl,
    size = 90,
    xs,
    sm,
    md,
    lg,
    defaultImageSrc = EMPTY_IMAGE,
    border = false,
    borderOptions,
    rounded = false,
    shadow = false,
    background,
    alt = t`Avatar`,
    showTooltip = false,
}: Props) {
    const {color} = useTheme();
    return (
        <div
            css={[
                styles.container({
                    size,
                    background: background ?? color.fill.neutral.strong,
                    rounded,
                    shadow,
                }),
                style,
            ]}
        >
            <ResponsiveImage
                imageUrl={imageUrl}
                width={size}
                heightRule={ASPECT_RATIO_1_1}
                alt={alt}
                showTooltip={showTooltip}
                type="avatar"
                xs={xs}
                sm={sm}
                md={md}
                lg={lg}
                border={border}
                borderOptions={borderOptions}
                rounded={rounded}
                renderDefaultImage={() => (
                    <Image
                        src={defaultImageSrc}
                        alt={alt}
                        showTooltip={showTooltip}
                        type="avatar"
                        border={border}
                        borderOptions={borderOptions}
                        rounded={rounded}
                        wrapped
                        data-di-mask="true"
                        data-hj-masked="true"
                    />
                )}
            />
        </div>
    );
}

import {css} from "@emotion/react";
import {type Theme, styled} from "@atg-ui/toolkit/theme";
import {common} from "@atg-ui/toolkit/theme/colors";
import type {BorderProps} from "@atg-tillsammans-shared/image-types";

export const DEFAULT_BORDER: BorderProps = {
    color: common.white,
    xs: 2,
    sm: 4,
};

export const fixedBorder = (width: number): BorderProps => ({
    xs: width,
});

const image = css`
    width: 100%;
    position: relative;
`;

export const imageBorder = ({
    theme,
    color = common.white,
    xs = 2,
    sm,
    md,
    lg,
    borderRadius,
}: BorderProps & {theme: Theme}) => css`
    border: ${xs}px solid ${color};

    ${borderRadius &&
    css`
        border-radius: ${borderRadius};
    `}

    ${sm &&
    css`
        ${theme.breakpoints.up("sm")} {
            border: ${sm}px solid ${color};
        }
    `}

    ${md &&
    css`
        ${theme.breakpoints.up("md")} {
            border: ${md}px solid ${color};
        }
    `}

    ${lg &&
    css`
        ${theme.breakpoints.up("lg")} {
            border: ${lg}px solid ${color};
        }
    `}
`;

const fixedHeight = css`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    object-fit: cover;
`;

export const Img = styled("img")<{
    borderOptions?: BorderProps;
    rounded?: boolean;
    wrapped?: boolean;
}>(
    ({theme, borderOptions, rounded, wrapped}) => css`
        ${borderOptions && imageBorder({theme, ...borderOptions})};
        ${rounded && "border-radius: 50%"};
        ${image};
        ${wrapped && fixedHeight};
    `,
);

export default Img;

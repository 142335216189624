import {getApolloClient, ApolloClientInstance} from "@atg-tillsammans/apollo-data-access";
import {TeamMembersDocument} from "@atg-tillsammans/graphql/query/__generated__/memberQueries.graphql.generated";
import {MutableMemberTeamFieldsFragmentDoc} from "@atg-tillsammans/graphql/fragment/__generated__/teamFragments.graphql.generated";
import {
    CreateTeamDocument,
    type CreateTeamMutationVariables,
    type CreateTeamMutation,
} from "@atg-tillsammans/graphql/mutation/__generated__/teamMutations.graphql.generated";
import {
    type MyTeamsQuery,
    MyTeamsDocument,
    type MyTeamsQueryVariables,
} from "@atg-tillsammans/graphql/query/__generated__/teamQueries.graphql.generated";
import {type TeamID} from "@atg-tillsammans/types";
import type {CreateApplicationInput, MemberTeam} from "@atg-tillsammans/types/generated";
import {
    CreateApplicationDocument,
    type CreateApplicationMutation,
} from "@atg-tillsammans/graphql/mutation/__generated__/applicationMutations.graphql.generated";

export const MemberTeamResolvers = {
    incrementActivatedRoundsAmount: (team?: MemberTeam | null) => {
        if (!team) return undefined;
        return {
            ...team,
            activatedRoundsAmount: team.activatedRoundsAmount
                ? team.activatedRoundsAmount + 1
                : 1,
        };
    },
    incrementUnreadMessages: (team?: MemberTeam | null) => {
        if (!team) return undefined;
        return {
            ...team,
            unreadComments: team.unreadComments ? team.unreadComments + 1 : 1,
        };
    },
    updateUnreadMessages: (nrOfUnreadComments: number) => (team?: MemberTeam | null) => {
        if (!team) return undefined;
        return {
            ...team,
            unreadComments: nrOfUnreadComments,
        };
    },
};

export class TeamService {
    static async fetchMyTeams(refreshTeams = false) {
        // Note: If called outside the apollo context return null
        if (!ApolloClientInstance.exists()) return null;

        const client = getApolloClient();

        if (client) {
            const {data} = await client.query<MyTeamsQuery>({
                query: MyTeamsDocument,
                variables: {
                    refreshTeams,
                },
                fetchPolicy: "network-only",
            });

            return data?.myTeams ?? [];
        }
        return null;
    }

    static async getMyTeams(variables?: MyTeamsQueryVariables) {
        const client = getApolloClient();
        if (client) {
            const data = await client.cache.readQuery<MyTeamsQuery>({
                query: MyTeamsDocument,
                variables,
            });
            return data?.myTeams ?? [];
        }
        return null;
    }

    static setMyTeams(teams: Array<MemberTeam>) {
        const client = getApolloClient();
        if (client) {
            /*
             *  Update apollo cache with teams.
             */
            client.cache.writeQuery<MyTeamsQuery>({
                query: MyTeamsDocument,
                data: {
                    myTeams: teams,
                },
            });
        }
    }

    static async refreshMyTeams({
        refreshTeams = false,
        fetchMemberTeamsCallback,
    }: {
        refreshTeams?: boolean;
        fetchMemberTeamsCallback?: (teams: Array<MemberTeam>) => void;
    } = {}) {
        const client = getApolloClient();
        if (client) {
            await client
                .query<MyTeamsQuery>({
                    query: MyTeamsDocument,
                    variables: {
                        refreshTeams,
                    },
                    fetchPolicy: "network-only",
                })
                .then(({data}) => {
                    client.cache.writeQuery({query: MyTeamsDocument, data});
                    if (fetchMemberTeamsCallback)
                        fetchMemberTeamsCallback(data?.myTeams ?? []);
                });
        }
    }

    static incrementActivatedRoundsAmount(teamId: TeamID) {
        const client = getApolloClient();
        if (!client) return;
        client.cache.updateFragment(
            {
                id: `MemberTeam:${teamId}`,
                fragment: MutableMemberTeamFieldsFragmentDoc,
            },
            MemberTeamResolvers.incrementActivatedRoundsAmount,
        );
    }

    static incrementUnreadMessages(teamId: TeamID) {
        const client = getApolloClient();
        if (!client) return;
        client.cache.updateFragment(
            {
                id: `MemberTeam:${teamId}`,
                fragment: MutableMemberTeamFieldsFragmentDoc,
            },
            MemberTeamResolvers.incrementUnreadMessages,
        );
    }

    static updateUnreadMessages(teamId: TeamID, nrOfUnreadComments: number) {
        const client = getApolloClient();
        if (!client) return;
        client.cache.updateFragment(
            {
                id: `MemberTeam:${teamId}`,
                fragment: MutableMemberTeamFieldsFragmentDoc,
            },
            (team?: MemberTeam | null) => {
                if (!team) return undefined;
                return {
                    ...team,
                    unreadComments: nrOfUnreadComments,
                };
            },
        );
    }

    static fetchTeamMembers(teamId: string) {
        const client = getApolloClient();
        if (client) {
            client.query({
                query: TeamMembersDocument,
                variables: {
                    teamId,
                },
                fetchPolicy: "network-only",
            });
        }
    }

    static async createTeam(variables: CreateTeamMutationVariables) {
        const client = getApolloClient();
        if (client) {
            const {data, errors} = await client.mutate<CreateTeamMutation>({
                mutation: CreateTeamDocument,
                variables,
            });
            return {team: data?.createTeam, errors};
        }
        return null;
    }

    static async joinTeam(input: CreateApplicationInput) {
        const client = getApolloClient();
        if (client) {
            const {data, errors} = await client.mutate<CreateApplicationMutation>({
                mutation: CreateApplicationDocument,
                variables: {input},
            });
            return [data?.createApplication.status, errors];
        }
        return [undefined, undefined];
    }
}

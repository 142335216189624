import {InMemoryCache} from "@apollo/client";
import type {InMemoryCacheConfig} from "@apollo/client";
import type {TeamSearchResponse} from "@atg-tillsammans/types/generated";
import fragmentMatcher from "@atg-tillsammans/types/generated/possibleTypes.generated";
import {ImageUtils} from "@atg-tillsammans-shared/image-utils";
import * as ReactiveVariables from "./reactiveVariables";

const toImageRef = (value: unknown) =>
    value ? ImageUtils.getImageRef(`${value}`) : value;

const cacheConfig: InMemoryCacheConfig = {
    /*
     * Genereated declarations for interfaces and unions so that inline fragments works in queries
     */
    possibleTypes: fragmentMatcher.possibleTypes,
    typePolicies: {
        Round: {
            fields: {
                isGameInitiator: {
                    read(_, {readField}) {
                        const memberId = readField(
                            "memberId",
                            readField("gameInitiator"),
                        );
                        return memberId === ReactiveVariables.memberIdVar();
                    },
                },
            },
        },
        Team: {
            fields: {
                isTeamCaptain: {
                    read(_, {readField}) {
                        const memberId = readField("memberId", readField("captain"));
                        return memberId === ReactiveVariables.memberIdVar();
                    },
                },
                teamImageUrl: {
                    read: toImageRef,
                },
            },
        },
        MemberTeam: {
            fields: {
                isTeamCaptain: {
                    read(_, {readField}) {
                        const captainId = readField("captain");
                        return captainId === ReactiveVariables.memberIdVar();
                    },
                },
            },
        },
        TeamSearchResult: {
            fields: {
                teamImageRef: {
                    read: toImageRef,
                },
            },
        },
        Member: {
            fields: {
                imageUrl: {
                    read: toImageRef,
                },
            },
        },
        Query: {
            fields: {
                teamSearch: {
                    keyArgs: (args, {field, fieldName}) =>
                        field?.alias?.value ?? fieldName,
                    merge(
                        existing: TeamSearchResponse,
                        incoming: TeamSearchResponse,
                        {args},
                    ): TeamSearchResponse {
                        const page = args?.page ?? 0;

                        if (!existing || page === 0) return incoming;

                        const mergedResults = existing ? existing.results.slice(0) : [];

                        const numberOfExistingResults = mergedResults.length;

                        for (let i = 0; i < incoming.results.length; ++i) {
                            const nextIndex = numberOfExistingResults + i;

                            if (nextIndex < incoming.nbrOfResults) {
                                mergedResults[numberOfExistingResults + i] =
                                    incoming.results[i];
                            }
                        }

                        return {
                            ...existing,
                            results: mergedResults,
                        };
                    },
                },
                /**
                 * Prevent console warnings for:
                 *
                 * Cache data may be lost when replacing the myTeams field of a Query object.
                 */
                myTeams: {
                    merge(existing, incoming) {
                        return incoming;
                    },
                },
            },
        },
    },
};

const createCache = (config = cacheConfig) => new InMemoryCache(config);

export {cacheConfig, createCache};

import type {SagaIterator} from "redux-saga";
import {takeLatest, takeLeading, put, select} from "redux-saga/effects";
import {LOGOUT_INITIATED} from "@atg-global-shared/user/userActionTypes";
import {ApolloClientInstance} from "@atg-tillsammans/apollo-data-access";
import {AuthSelectors} from "@atg-shared/auth";
import {TeamActions, TeamSelectors} from "@atg-tillsammans/team-data-access/redux/team";
import {AppActions} from "./appActions";
import {APP_CONTEXT_ENTER} from "./appConstants";

export async function clearApolloCache() {
    await ApolloClientInstance.clearCache();
}

export function* initTillsammans(): SagaIterator {
    const isLoggedIn = yield select(AuthSelectors.isLoggedIn);

    if (!isLoggedIn) return;

    yield put(AppActions.userEnteredTillsammans());

    const teamsNotLoaded = yield select(TeamSelectors.isTeamsNotLoaded);

    if (teamsNotLoaded) yield put(TeamActions.fetchMyTeams());
}

export function* appSaga(): SagaIterator {
    yield takeLeading(APP_CONTEXT_ENTER, initTillsammans);
    yield takeLatest(LOGOUT_INITIATED, clearApolloCache);
}

import type {
    ImageSize,
    ImageProps,
    ImageUrl,
    ImageRef,
    ImageAspectRatio,
} from "@atg-tillsammans-shared/image-types";
import * as ImageHandlerUtils from "./imageHandlerUtils";

const resolvAspectRatio = (width: number, aspectRatio: ImageAspectRatio): ImageSize => ({
    width,
    height: Math.ceil(width * aspectRatio.heightMultiplicand),
});

const getResolutions = (params: ImageProps): string | null => {
    const {imageUrl, width = 0, height = 0} = params;
    if (!ImageHandlerUtils.isValidUrl(imageUrl) || (width === 0 && height === 0))
        return null;
    const x1 = ImageHandlerUtils.size({
        imageUrl,
        width,
        height,
    });
    const x2 = ImageHandlerUtils.size({
        imageUrl,
        width: width * 2,
        height: height * 2,
    });
    return [`${x1} 1x`, `${x2} 2x`].join(",");
};

const forAspectRatio = (aspectRatio: ImageAspectRatio) => ({
    getSrcSet: ({imageUrl, width = 0}: ImageProps) =>
        getResolutions({imageUrl, ...resolvAspectRatio(width, aspectRatio)}),
    getSrc: ({imageUrl, width = 0}: ImageProps) =>
        ImageHandlerUtils.size({imageUrl, ...resolvAspectRatio(width, aspectRatio)}),
});

const forFixedHeight = (height: number) => ({
    getSrcSet: ({imageUrl, width = 0}: ImageProps) =>
        getResolutions({imageUrl, width, height}),
    getSrc: ({imageUrl, width = 0}: ImageProps) =>
        ImageHandlerUtils.size({imageUrl, width, height}),
});

const getImageRef = (imageUrl: ImageUrl | string): ImageRef =>
    imageUrl.replace(/^.*[\\/]/, "");

export {resolvAspectRatio, getResolutions, forAspectRatio, forFixedHeight, getImageRef};

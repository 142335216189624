// BE: GameType (https://github.com/atgse/atg-service-team/blob/master/atg-service-team-app/src/main/java/se/atg/service/team/model/common/GameType.java)
export enum GameType {
    BIG9 = "BIG9",
    GS75 = "GS75",
    V64 = "V64",
    V75 = "V75",
    V86 = "V86",
    V65 = "V65",
    V4 = "V4",
    TOP7 = "TOP7",
    /* Added for Cooupon Team compability */
    VINNARE = "VINNARE",
    PLATS = "PLATS",
    VP = "VP",
    RAKET = "RAKET",
    TVILLING = "TVILLING",
    KOMB = "KOMB",
    TRIO = "TRIO",
    LD = "LD",
    DD = "DD",
    V3 = "V3",
    V5 = "V5",
}

// BE: Track (https://github.com/atgse/atg-service-team/blob/master/atg-service-team-app/src/main/java/se/atg/service/team/model/game/Track.java)
export type Track = {
    id: number;
    name: string;
};

// BE: GameStatus (https://github.com/atgse/atg-service-team/blob/master/atg-service-team-app/src/main/java/se/atg/service/team/model/game/GameStatus.java)
export enum GameStatus {
    SCHEDULED = "SCHEDULED",
    UPCOMING = "UPCOMING",
    BETTABLE = "BETTABLE",
    STARTING = "STARTING",
    ONGOING = "ONGOING",
    RESULTS = "RESULTS",
    CANCELLED = "CANCELLED",
    INVALID = "INVALID",
}

/** Needed for backward compability remove when .flow file is not needed anymore */
export type GameStatusEnum = GameStatus;

export type GameID = string;

// BE: GameId (https://github.com/atgse/atg-service-team/blob/master/atg-service-team-app/src/main/java/se/atg/service/team/model/game/GameId.java)
export type GameId = {
    gameType: GameType;
    gameDate: string;
    gameAreaCode: number;
    gameRaceNumber: number;
};

export interface Game {
    id: GameID;
    gameId: GameId;
    gameStatus: GameStatus;
    tracks: Array<Track>;
    scheduledStartTime: string;
    endTime: string;
}

import type {ImageUrl} from "@atg-tillsammans-shared/image-types";

export enum AuthorType {
    MEMBER = "MEMBER",
    SYSTEM = "SYSTEM",
    INFO = "INFO",
    TEAM_CAPTAIN = "TEAM_CAPTAIN",
    BET_PLACER = "BET_PLACER",
    MODERATOR = "MODERATOR",
    GHOST_WRITER = "GHOST_WRITER",
    AGENCY_ADMIN = "AGENCY_ADMIN",
}

/** Needed for backward compability remove when .flow file is not needed anymore */
export type MessageAuthorType = AuthorType;

export enum ChatAllowedAction {
    BLOCK_COMMENTS = "BLOCK_COMMENTS",
    REMOVE_COMMENTS = "REMOVE_COMMENTS",
    UPDATE_CHAT = "UPDATE_CHAT",
    PIN_COMMENT = "PIN_COMMENT",
}

/** Needed for backward compability remove when .flow file is not needed anymore */
export type ChatAllowedActionType = ChatAllowedAction;

export type ChannelId = string;

export type Member = {
    id: number;
    name?: string;
    displayName?: string;
    firstName?: string;
    lastName?: string;
    imageUrl?: ImageUrl;
};

export type MyChannelStats = {
    nrOfUnreadComments: number;
};

export type ChannelInfo = {
    channelId: ChannelId;
    allowedActions: ChatAllowedAction[];
    memberId: number | null;
    header: string;
    subHeader?: string;
    buttonTitle: string;
    getTag: (messageAuthorType: MessageAuthorType) => string | null;
    mapErrorCode: (id: string | null) => string;
};

export type OnlineMember = {
    firstName: string;
    lastName: string;
    imageUrl?: ImageUrl;
    online?: boolean;
};

export type OnlineInfo = {
    members: OnlineMember[];
    visible: boolean;
    showExactNum?: boolean;
};

export type Message = {
    type: "message";
    id: number;
    channelId: ChannelId;
    timestamp: string;
    text: string;
    user: Member;
    authorType: MessageAuthorType;
    pinned?: boolean;
};

export type MessageAction = {
    actionType: "CREATE" | "UPDATE" | "DELETE" | "PIN";
    comment: Message;
};

import "@atg-shared/micro-frontend/setPublicPath";
import log, {serverLogPlugin} from "@atg-shared/log";
import {configureStoreForAuthentication} from "@atg-shared/auth";
import {ApolloClientInstance} from "@atg-tillsammans/apollo-data-access";
import Features, {apolloDevTools} from "@atg-shared/client-features";
import {initialiseLanguage} from "@atg-shared/language";
import {configureEmotionCache} from "atg-emotion-cache";

import {store as tillsammansStore} from "./redux/store";

serverLogPlugin(log, "tillsammans");
log.setLevel("warn");
configureStoreForAuthentication(tillsammansStore);
configureEmotionCache("tillsammans");

initialiseLanguage(async (locale) => {
    const {messages} = await import(
        /* webpackChunkName: "i18n" */ `../locales/${locale}/messages.po`
    );
    return messages;
});

ApolloClientInstance.create(tillsammansStore.dispatch, {
    logger: log,
    devToolsEnabled: Features.isEnabled(apolloDevTools),
});

export const SideMenu = () =>
    import(/* webpackChunkName: "SideMenu" */ "./components/SideMenu");

export const App = () => import(/* webpackChunkName: "App" */ "./components/App");

import {combineReducers} from "redux";
import type {SharedBetCouponState} from "./sharedBet.types";
import {
    type SharedBetAction,
    STARTED_COUPONS_ERROR,
    STARTED_COUPONS_FETCH,
    STARTED_COUPONS_RESET,
    STARTED_COUPONS_SUCCESS,
} from "./sharedBet.actions";

const INITIAL_COUPONS_STATE: SharedBetCouponState = {
    data: {},
    loading: false,
    error: null,
};

const participations = (
    state = INITIAL_COUPONS_STATE,
    action: SharedBetAction,
): SharedBetCouponState => {
    switch (action.type) {
        case STARTED_COUPONS_FETCH:
            return {
                ...state,
                loading: true,
            };
        case STARTED_COUPONS_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                loading: false,
            };
        case STARTED_COUPONS_ERROR:
            return {
                ...state,
                error: action.payload.error,
                loading: false,
            };
        case STARTED_COUPONS_RESET:
            return INITIAL_COUPONS_STATE;

        default:
            return state;
    }
};

const sharedBetReducer = combineReducers({
    participations,
});

export default sharedBetReducer;

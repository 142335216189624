import {createSelector} from "reselect";
import {fetchSelectors} from "@atg-shared/fetch-redux";
import type {Pagination} from "atg-fetch-pagination/domain/pagination";
import type {State} from "./index";

const getChannelState = (state: State) => state.chat.channel;
export const getChannelMessages = (state: State) => getChannelState(state).messages;
export const {isLoading: isChannelMessagesLoading, hasError: hasChannelMessagesError} =
    fetchSelectors.createSelectors(getChannelState);

export const getLoadingStatus = (state: State) =>
    fetchSelectors.getLoadingStatus(getChannelState(state));

export const getPagination = (state: State): Pagination => {
    const {page, hasMore} = getChannelState(state);
    return {page, hasMore};
};

export const isDialogOpen = (state: State): boolean => getChannelState(state).dialogOpen;

const getPostMessageState = (state: State) => state.chat.postMessage;
export const getPostMessageText = (state: State) =>
    getPostMessageState(state).messageText;
export const getPostMessageLoadingStatus = (state: State) =>
    fetchSelectors.getLoadingStatus(getPostMessageState(state));
export const {isLoading: isPostMessageLoading, hasError: hasPostMessageError} =
    fetchSelectors.createSelectors(getPostMessageState);

export const getChannelMessagesAscending = (state: State) =>
    [...getChannelMessages(state)].sort(
        (message1, message2) =>
            new Date(message1.timestamp).getTime() -
            new Date(message2.timestamp).getTime(),
    );
export const getChannelMessagesDescending = (state: State) =>
    [...getChannelMessages(state)].sort(
        (message1, message2) =>
            new Date(message2.timestamp).getTime() -
            new Date(message1.timestamp).getTime(),
    );

const getSaveChatMessageState = (state: State) => state.chat.saveMessage;
export const {isLoading: isSaveMessageLoading, isLoaded: isSaveMessageLoaded} =
    fetchSelectors.createSelectors(getSaveChatMessageState);
export const saveMessageError = (state: State) => getSaveChatMessageState(state).error;

const getRemoveChatMessageState = (state: State) => state.chat.removeMessage;
export const {
    isLoading: isRemoveMessageLoading,
    isLoaded: isRemoveMessageLoaded,
    hasError: hasRemoveMessageError,
} = fetchSelectors.createSelectors(getRemoveChatMessageState);
export const isRemoveMessageSuccessful = createSelector(
    isRemoveMessageLoaded,
    hasRemoveMessageError,
    (loaded, hasError) => loaded && !hasError,
);
export const removeMessageError = (state: State) =>
    getRemoveChatMessageState(state).error;

const getReportChatMessageState = (state: State) => state.chat.reportMessage;
export const isReportMessageLoading = (state: State) =>
    fetchSelectors.isLoading(getReportChatMessageState(state));
export const reportMessageError = (state: State) =>
    getReportChatMessageState(state).error;

export const isReportMessageLoaded = (state: State) =>
    fetchSelectors.isLoaded(getReportChatMessageState(state));

export const isReportMessageSuccessful = createSelector(
    isReportMessageLoaded,
    reportMessageError,
    (loaded, hasError) => loaded && !hasError,
);

const getBlockChatMemberState = (state: State) => state.chat.blockMember;
export const isBlockMemberLoading = (state: State) =>
    fetchSelectors.isLoading(getBlockChatMemberState(state));
export const isBlockMemberLoaded = (state: State) =>
    fetchSelectors.isLoaded(getBlockChatMemberState(state));
export const blockMemberError = (state: State) => getBlockChatMemberState(state).error;

export const isBlockMemberSuccessful = createSelector(
    isBlockMemberLoaded,
    blockMemberError,
    (loaded, hasError) => loaded && !hasError,
);
